<template>
  <div class="order-details-container wrapper">
    <CRow class>
      <CCol lg="12" md="12">
        <CCard>
          <CCardHeader>
            <h1>{{$t('REWARD_REQUEST_DETAIL_PAGE_TITLE')}}</h1>
          </CCardHeader>

          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>

          <CCardBody>
            <ValidationObserver ref="form" v-if="!isLoading">
              <CForm>
                <CRow class="form-group">
                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{$t('REWARD_REQUEST_DETAIL_PAGE_LABEL_ID')}}:</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <div class="form-control-plaintext">
                      <span>{{rewardDetails.id}}</span>
                    </div>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{$t('REWARD_REQUEST_DETAIL_PAGE_LABEL_STATUS')}}:</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <div class="form-control-plaintext">
                      <StakingBadge type="reward-requests-status" :text="rewardDetails.status" />
                    </div>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label class="col-form-label">{{$t('REWARD_REQUEST_DETAIL_PAGE_LABEL_DATE')}}:</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <div class="form-control-plaintext">
                      <span class="from-date">{{rewardDetails.from_date | moment(dateFormat)}}</span>
                      -
                      <span
                        class="to-date"
                      >{{rewardDetails.to_date | moment(dateFormat)}}</span>
                    </div>
                  </CCol>

                  <CCol lg="2" md="2">
                    <label
                      class="col-form-label"
                    >{{$t('REWARD_REQUEST_DETAIL_PAGE_LABEL_AFFILIATE_TYPE')}}:</label>
                  </CCol>
                  <CCol lg="10" md="10">
                    <div class="form-control-plaintext">
                      <span class="payment-type">{{rewardDetails.affiliate_type}}</span>
                    </div>
                  </CCol>
                </CRow>

                <ValidationProvider
                  name="REWARD_REQUEST_DETAIL_PAGE_LABEL_ERROR_MESSAGE"
                  v-if="rewardDetails.error_message"
                >
                  <CRow class="form-group" slot-scope="{ errors}">
                    <CCol lg="2" md="2">
                      <label
                        class="col-form-label"
                      >{{$t('REWARD_REQUEST_DETAIL_PAGE_LABEL_ERROR_MESSAGE')}}:</label>
                    </CCol>
                    <CCol lg="10" md="10">
                      <textarea
                        type="text"
                        class="form-control"
                        v-model="rewardDetails.error_message"
                        rows="5"
                        readonly="true"
                      />
                      <span class="error-msg-wrap">
                        <span class="error-msg">{{errors[0]}}</span>
                      </span>
                    </CCol>
                  </CRow>
                </ValidationProvider>

                <CCard class="reward-request-list-table-card mt-5">
                  <CDataTable :items="items" :fields="fields" :itemsPerPage="limit" fixed hover striped bordered>
                    <template ##="{item, index}">
                      <td>{{index + 1 + (activePage-1)*limit}}</td>
                    </template>

                    <template #amount="{item}">
                      <td>
                        <StakingCurrency
                          :value="item.amount"
                          :currencySymbol="item.currency_symbol"
                        />
                      </td>
                    </template>

                    <template #status="{item}">
                      <td>
                        <StakingBadge type="reward-requests-status" :text="item.status" />
                      </td>
                    </template>
                    <template #action="{item}">
                      <td>
                        <i
                          v-if="item.status == 'COMPLETED'"
                          @click="showRewardRequestDetails(item.id)"
                        >
                          <CIcon class="view-rewards" name="cil-notes" />
                        </i>
                      </td>
                    </template>
                  </CDataTable>
                </CCard>
              </CForm>
              <StakingPagination
                :limit="limit"
                :total="total"
                :activePage="activePage"
                @changePage="onChangePage"
                @changePageSize="onChangePageSize"
              />
            </ValidationObserver>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CModal
      class="caculate-reward-request-modal"
      :show.sync="isShowModal"
      :centered="true"
      size="lg"
    >
      <template #header>
        <label class="modal-tit">{{$t('REWARD_REQUEST_DETAIL_PAGE_MODAL_REWARD_DETAIL_TEXT_TITLE')}}</label>
        <CButton class="close-modal" @click="isShowModal=false">
          <CIcon name="cil-x" />
        </CButton>
      </template>
      <div class="caculate-modal-body">
        <CDataTable
          :items="detailItemAmount"
          :fields="rewardField"
          fixed
          hover
          striped
          bordered
          class="table-class"
        >
          <template #amount="{item}">
            <td>
              <StakingCurrency :value="item.amount" :currencySymbol="item.currency_symbol" />
            </td>
          </template>
          <template #level="{item}">
            <td>{{item.level ? item.level : '-'}}</td>
          </template>

          <template #setting="{item}">
            <td>
              <div v-if="item.setting">
                <div>
                  <span
                    class="mr-1"
                  >{{$t('REWARD_REQUEST_DETAIL_PAGE_MODAL_REWARD_DETAIL_TABLE_PROPORTION_SHARE')}}</span>
                  <span>{{(item.setting.proportion_share / 100) | percent}}</span>
                </div>

                <div>
                  <div>
                    <span
                      class="mr-1"
                    >{{$t('REWARD_REQUEST_DETAIL_PAGE_MODAL_REWARD_DETAIL_TABLE_RATE')}}</span>
                    <span>{{getRate(item) | percent }}</span>
                  </div>
                </div>
              </div>
            </td>
          </template>
        </CDataTable>
      </div>
    </CModal>
  </div>
</template>
<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import moment from 'moment';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import { MembershipOrderStatus } from '@/enums';
import StakingBadge from '@/components/StakingBadge';
import StakingCurrency from '@/components/StakingCurrency';
import { Constants } from '@/constants';
import StakingPagination from '@/components/StakingPagination';

const getRate = item => {
  const level = item.level || 0;
  const { type, rates, membership_rate, membership_type_id } = item.setting;

  if (type === 'AFFILIATE') {
    if (level === 0) {
      return null;
    }

    if (rates.length >= level - 1) {
      const rate = Number(rates[level - 1]) / 100;

      return rate;
    }

    return null;
  }

  if (type === 'MEMBERSHIP') {
    const rate = Number(membership_rate[membership_type_id]) / 100;

    return rate;
  }
};

export default {
  name: 'OderDetails',
  components: {
    VclList,
    StakingBadge,
    StakingCurrency,
    StakingPagination,
  },
  data() {
    return {
      items: [],
      rewardDetails: {},
      detailItemAmount: [],
      isLoading: true,
      options: [],
      dateFormat: Constants.dateFormat,
      isAllowedViewDetails: false,
      isAllowedViewListDetails: false,
      isFormChanged: false,
      isShowModal: false,
      fields: [
        '#',
        {
          key: 'ext_client_id',
          label: this.$t('REWARD_REQUEST_DETAIL_PAGE_TABLE_COL_FROM'),
        },
        {
          key: 'amount',
          label: this.$t('REWARD_REQUEST_DETAIL_PAGE_TABLE_COL_AMOUNT'),
        },
        {
          key: 'status',
          label: this.$t('REWARD_REQUEST_DETAIL_PAGE_TABLE_COL_STATUS'),
        },
        {
          key: 'action',
          label: '',
        },
      ],
      rewardField: [
        {
          key: 'ext_client_id',
          label: this.$t('REWARD_REQUEST_DETAIL_PAGE_MODAL_REWARD_DETAIL_TABLE_COL_EMAIL'),
        },
        {
          key: 'amount',
          label: this.$t('REWARD_REQUEST_DETAIL_PAGE_MODAL_REWARD_DETAIL_TABLE_COL_AMOUNT'),
        },
        {
          key: 'policy',
          label: this.$t('REWARD_REQUEST_DETAIL_PAGE_MODAL_REWARD_DETAIL_TABLE_COL_POLICY'),
        },
        {
          key: 'level',
          label: this.$t('REWARD_REQUEST_DETAIL_PAGE_MODAL_REWARD_DETAIL_TABLE_COL_LEVEL'),
        },
        {
          key: 'setting',
          label: this.$t('REWARD_REQUEST_DETAIL_PAGE_MODAL_REWARD_DETAIL_TABLE_COL_INFO'),
        },
      ],
      total: 0,
      limit: 10,
      activePage: 1,
      pageInput: 1,
      rewardRequestId: '',
      getRate: getRate,
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    const rewardRequestId = this.$route.params.rewardRequestId;
    this.rewardRequestId = rewardRequestId;
    this.checkPermissions();

    this.getCaculateRewardDetails(rewardRequestId);
    await this.getCaculateRewardListDetails();
  },
  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(items => {
          if (items.name === 'MEMBERSHIP_VIEW_CACULATOR_REWARD_REQUEST_DETAIL') {
            this.isAllowedViewDetails = true;
          }

          if (items.name === 'MEMBERSHIP_VIEW_CACULATOR_REWARD_REQUEST_DETAIL_LIST') {
            this.isAllowedViewListDetails = true;
          }
        });
      }
    },
    async getCaculateRewardDetails(rewardRequestId) {
      try {
        const res = await this.$http.get(endpoints.getCaculateRewardDetails(rewardRequestId));
        this.rewardDetails = res.data;
        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('REWARD_REQUEST_DETAIL_PAGE_NOTIFY_ERROR_MESSAGE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async getCaculateRewardListDetails() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
        };
        const res = await this.$http.get(endpoints.getCaculateRewardListDetails(this.rewardRequestId), { params });
        this.items = res.data.items;
        this.total = res.data.total;
        this.isLoading = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('REWARD_REQUEST_DETAIL_PAGE_NOTIFY_ERROR_MESSAGE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;

      await this.getCaculateRewardListDetails();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      await this.getCaculateRewardListDetails();
    },
    async showRewardRequestDetails(id) {
      this.detailItemAmount = await this.getCaculateRewardItemDetails(id);
      this.isShowModal = true;
    },
    async getCaculateRewardItemDetails(id) {
      try {
        const res = await this.$http.get(endpoints.getCaculateRewardItemDetails(id));
        return res.data;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: 'Error',
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>
<style lang="scss">
.order-details-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
    }

    .view-rewards {
      cursor: pointer;
    }

    .payment-type {
      display: inline-block;
    }

    .explorer-link {
      display: inline-block;
    }

    .form-group {
      margin-bottom: 0.5rem;
      label {
        font-weight: 500;
      }
    }

    .membership-order-status {
      background: #62d935;
      color: #fff;
    }
    .form-actions {
      text-align: right;
      .btn {
        border: none;
        border-radius: 32px;
        width: 200px;
        height: 32px;
        padding: 0 30px;
        color: #fff;
        margin: 0 0 15px 15px;

        &.btn-primary {
          background: #3b4b7e;
        }
      }
    }
  }
  .caculate-reward-request-modal {
    .modal-header {
      padding: 10px 15px 5px;
      border: none;
      .modal-tit {
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        line-height: 38px;
        color: #657187;
      }
    }
    .modal-body {
      table {
        margin: 0;
        border: 1px solid #dadada;
        thead {
          tr {
            th {
              font-size: 14px;
              font-weight: 700;
              color: #4D5666;
              padding-top: 10px;
              padding-bottom: 10px;
            }
          }
        }
      }
    }
    .modal-footer {
      display: none;
    }
  }
}
</style>
